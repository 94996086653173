<template lang="">
    <div>
        {{message}}
    </div>
</template>
<script>
export default {
    data() {
        return {
            message: ''
        }
    },
    
    mounted() {
        this.$store.commit('SET_LOADING_STATE', true);
        this.$store.dispatch('signOut');
        setTimeout(()=>{
            this.$store.commit('SET_LOADING_STATE', false);
            this.$router.push( { path: "/" } );
        }, 0);
    },
}
</script>